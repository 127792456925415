import * as React from 'react'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import './scss/about.scss'

export default function aboutPage () {
	return (
		<main>
			<Navbar></Navbar>

			<div className='about'>
				<h1>About Us</h1>
			</div>

			<Footer></Footer>
		</main>
	)
}